<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="1000px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.num_facture"
                    label="N° Facture"
                    :rules="[(v) => !!v || 'N° Factur obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <datepicker
                    label="Date Facture"
                    :outlined="true"
                    v-model="editedItem.date_facture"
                    :edit="true"
                    :rules="[(v) => !!v || 'Date Facture obligatoire']"
                  ></datepicker>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    type="number"
                    outlined
                    dense
                    v-model.number="editedItem.montant"
                    label="Montant Facture"
                    :rules="[(v) => !!v || 'Montant Obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                    hide-spin-buttons
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.comment"
                    label="Comment"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <listitems
                    :list="items"
                    :headers="products_headers"
                    :master="true"
                    :showstd="true"
                    :Total="true"
                    :key="kp"
                    :add="false"
                    :Update="true"
                    :del="false"
                    @change="product_change"
                    :elevation="0"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_DOSSIER_FACTURE from "../graphql/Import/CREATE_DOSSIER_FACTURE.gql";
import UPDATE_DOSSIER_FACTURE from "../graphql/Import/UPDATE_DOSSIER_FACTURE.gql";

export default {
  name: "dossierfactureform",
  components: {
    listitems: () => import("./ListItems.vue"),
    datepicker: () => import("./DatePicker.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    dossier_id: Number,
    list: Array,
    cmddetails: Array,
    modify: Boolean,
  },

  data: () => ({
    valid: true,
    items: [],
    editedItem: {},
    defaultItem: {
      id: -1,
      num_facture: "",
      date_facture: "",
      montant: 0,
      comment: "",
    },
    products_headers: [
      {
        text: "Code",
        value: "code",
        selected: true,
        align: "left",
        width: "140px",
      },
      {
        text: "Produit",
        value: "label",
        slot: "href99",
        selected: true,
        align: "left",
      },
      {
        text: "PU",
        value: "pu",
        width: "100px",
        edit: true,
        type: "number",
        sm: 4,
        md: 4,
        selected: true,
      },

      {
        text: "Qte Rest.",
        value: "rest_import",
        total: "rest_import",
        width: "120px",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "Qte Fact.",
        value: "qte_fact",
        total: "qte_fact",
        slot: "cur",
        align: "end",
        edit: true,
        type: "qte",
        max_qte: true,
        sm: 4,
        md: 4,
        selected: true,
      },
      {
        text: "Montant",
        value: "montant",
        total: "montant",
        slot: "cur",
        align: "end",
        width: "180px",

        selected: true,
      },

      {
        text: "Comment",
        value: "comment",
        selected: false,
      },
    ],
    kp: 120,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    formTitle() {
      return this.editedItem.id == -1 ? "Nouvelle Facture" : "Modifier Facture";
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.items = Object.assign(
          [],
          this.cmddetails.filter((elm) => elm.rest_import > 0)
        );
        this.$refs.form2.resetValidation();
      }
    }
  },
  methods: {
    async maj(query, v, refresh) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    close() {
      this.$emit("close");
    },
    product_change(item) {
      item.montant = item.pu * item.qte_fact;
      this.editedItem.montant = parseFloat(
        this.items.reduce((a, b) => a + (b["montant"] || 0), 0).toFixed(2)
      );
    },
    save() {
      if (this.$refs.form2.validate()) {
        if (this.editedItem.id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATE_DOSSIER_FACTURE,
              variables: {
                dossierimportfacture: {
                  id: this.editedItem.id,
                  num_facture: this.editedItem.num_facture,
                  date_facture: this.editedItem.date_facture,
                  montant: this.editedItem.montant,
                  comment: this.editedItem.comment,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.list.splice(this.editedItem.index, 1, this.editedItem);
              this.$emit("change");
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_DOSSIER_FACTURE,
              variables: {
                dossierimportfacture: {
                  dossier_id: this.dossier_id,
                  num_facture: this.editedItem.num_facture,
                  date_facture: this.editedItem.date_facture,
                  montant: this.editedItem.montant,
                  comment: this.editedItem.comment,
                  create_uid: this.$store.state.me.id,
                },
              },
            })
            .then((data) => {
              this.editedItem.id = data.data.createDossierImportFacture.id;
              //  this.create_detail_facture(this.editedItem.id);
              this.list.push(this.editedItem);
              this.$emit("add");
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        }
        this.close();
      }
    },
  },
};
</script>
